<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        为众多企业提供产品和服务
      </div>
      <div class="base flex flex-wrap justify-between">
        <div
          class="item"
          v-for="item in list"
          :style="{ backgroundImage: 'url(' + item.img + ')' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { img: require("@/assets/images/qiye/zt.png") },
        { img: require("@/assets/images/qiye/supermap.png") },
        { img: require("@/assets/images/qiye/sq.png") },
        { img: require("@/assets/images/qiye/zgyc.png") },
        { img: require("@/assets/images/qiye/cddt.png") },
        { img: require("@/assets/images/qiye/dzkd.png") },
        { img: require("@/assets/images/qiye/dth.png") },
        { img: require("@/assets/images/qiye/tfxq.png") },
        { img: require("@/assets/images/qiye/cygs.png") },
        { img: require("@/assets/images/qiye/cnzw.png") },
        { img: require("@/assets/images/qiye/mm.png") },
        { img: require("@/assets/images/qiye/jsf.png") },
        { img: require("@/assets/images/qiye/sltj.png") },
        { img: require("@/assets/images/qiye/ymd.png") },
        { img: require("@/assets/images/qiye/health.png") },
        { img: require("@/assets/images/qiye/jxy.png") },
        { img: require("@/assets/images/qiye/finnet.png") },
        { img: require("@/assets/images/qiye/cdmx.png") },
        { img: require("@/assets/images/qiye/jp.png") },
        { img: require("@/assets/images/qiye/wz.png") },
        { img: require("@/assets/images/qiye/lckw.png") },
        { img: require("@/assets/images/qiye/fxqq.png") },
        { img: require("@/assets/images/qiye/tflc.png") },
        { img: require("@/assets/images/qiye/gy.png") },
      ],
    };
  },

  methods: {},
};
</script>

<style lang='less' scoped>
.main {
  box-sizing: border-box;
  padding-top: 60px;
  height: 592px;
  background-color: #fff;
}
.index_tit {
  color: #17191f;
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
}
.base {
  margin-top: 50px;
  .item {
    width: 186px;
    height: 82px;
    background-size: 186px 82px;
    margin-top: 14px;
  }
}
</style>

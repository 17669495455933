<template>
  <div id="app">
    <!--<header-com :banner="$store.state.homeBanner"></header-com>-->
    <header-com />
    <div class="mian">
      <!--<router-view v-if="isShow"/>-->
      <router-view class="app-view" v-if="isShow" />
    </div>
    <footer-com />
  </div>
</template>

<script>
import headerCom from "@/components/common/header";
import footerCom from "@/components/common/footer";

export default {
  components: {
    headerCom,
    footerCom,
  },
  data() {
    return {
      isShow: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/") {
        this.$store.state.homeBanner = true;
      } else {
        this.$store.state.homeBanner = false;
      }
    },
  },
  // 提供可注入子组件属性
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      // 先隐藏
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
};
</script>
<style type="text/css">
@import url("./assets/style/common.css");
@import url("./assets/style/reset.css");
#app {
  background: #fff;
}
.app-view {
  display: block;
  width: 100%;
  overflow-x: hidden;
  margin-top: 76px;
}
</style>

<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        多款应用开箱即用
        <span>应用安装简便快捷，应用之间业务功能松耦合</span>
      </div>
      <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabs" :label="item.name" :name="item.type">
          <span slot="label" @mouseover="mouseOver(item.type)">{{
            item.name
          }}</span>
          <div class="base flex justify-between">
            <div
              class="card flex flex-col justify-between"
              v-for="item in manageData"
              v-if="item.type == 1"
            >
              <div
                class="item flex flex-col justify-between"
                v-for="it in item.list"
              >
                <div class="title">{{ it.name }}</div>
                <div class="sub_tit ellipsis-2">{{ it.content }}</div>
              </div>
              <!-- <div class="item flex flex-col justify-between"></div> -->
            </div>
            <div
              class="card card_img"
              v-for="item in manageData"
              v-if="item.type == 2"
              :style="{ backgroundImage: 'url(' + item.img + ')' }"
            ></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "peopleList",
      tabs: [
        { name: "人员管理", type: "peopleList" },
        { name: "安防监控", type: "securityList" },
        { name: "智能物联", type: "thingList" },
        { name: "专题地图", type: "topicMap" },
        { name: "企业管理", type: "enterpriseList" },
      ],
      manageData: [],
      list: {
        // 人员列表
        peopleList: [
          {
            type: 1,
            list: [
              {
                name: "实时音视频",
                content:
                  "私有化部署确保数据安全，稳定高质量的多平台一对一、一对多实时通话",
              },
              {
                name: "实名制管理",
                content:
                  "实名制管理工作人员，支持绑定人脸、设备、工资卡、合同等数据",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "人员定位",
                content:
                  "在地图上显示工作人员实时位置，支持查看详情、指派任务、视频连线等操作",
              },
              {
                name: "事件地图",
                content:
                  "基于空间位置在地图上进行各类型事件的展示，并可视化处置",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "监控地图",
                content:
                  "视频监控设备在地图上直观展示，支持视频画面实时播放、抓拍、云台等功能",
              },
              {
                name: "智能巡查",
                content: "功能开发中",
              },
            ],
          },
          { type: 2, img: require("@/assets/images/people.png") },
        ],
        // 安防列表
        securityList: [
          {
            type: 1,
            list: [
              {
                name: "视频监控地图",
                content: "功能开发中",
              },
              {
                name: "场地巡查",
                content: "智能巡查，人工巡查",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "智能门禁",
                content: "功能开发中",
              },
              {
                name: "事件管理",
                content: "功能开发中",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "隐患地图",
                content: "功能开发中",
              },
              {
                name: "风险地图",
                content: "功能开发中",
              },
            ],
          },
          { type: 2, img: require("@/assets/images/people.png") },
        ],
        // 物联列表
        thingList: [
          {
            type: 1,
            list: [
              {
                name: "车辆管理",
                content: "行进轨迹，电子围栏",
              },
              // {
              //   name: "场地巡查",
              //   content: "智能巡查，人工巡查",
              // },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "固定资产地图",
                content: "功能开发中",
              },
              // {
              //   name: "事件管理",
              //   content: "功能开发中",
              // },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "故障报修管理",
                content: "功能开发中",
              },
              // {
              //   name: "风险地图",
              //   content: "功能开发中",
              // },
            ],
          },
          { type: 2, img: require("@/assets/images/people.png") },
        ],
        // 专题地图
        topicMap: [
          {
            type: 1,
            list: [
              {
                name: "客户地图",
                content: "功能开发中",
              },
              {
                name: "订单地图",
                content: "功能开发中",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "网点地图",
                content: "功能开发中",
              },
              {
                name: "合同地图",
                content: "功能开发中",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "广告位地图",
                content: "功能开发中",
              },
              {
                name: "智能分单",
                content: "功能开发中",
              },
            ],
          },
          { type: 2, img: require("@/assets/images/people.png") },
        ],
        // 企业列表
        enterpriseList: [
          {
            type: 1,
            list: [
              {
                name: "实时天气",
                content: "天气、温度",
              },
              {
                name: "用水用电",
                content: "功能开发中",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "绿色施工",
                content: "PM2.5、噪声",
              },
              {
                name: "打卡签到",
                content: "功能开发中",
              },
            ],
          },
          {
            type: 1,
            list: [
              {
                name: "流程审批",
                content: "功能开发中",
              },
              {
                name: "在线课堂",
                content: "功能开发中",
              },
            ],
          },
          { type: 2, img: require("@/assets/images/people.png") },
        ],
      },
    };
  },
  mounted() {
    this.manageData = this.list.peopleList;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.manageData = this.list[tab.name];
    },
    mouseOver(type) {
      this.activeName=type
      this.manageData = this.list[type];
    },
  },
};
</script>

<style lang='less' scoped>
.index_tit {
  color: #000;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  height: 74px;
  span {
    font-size: 16px;
    color: #797c80;
    line-height: 16px;
    font-weight: 400;
  }
}
.main {
  &/deep/.tabs {
    margin-top: 50px;
    .el-tabs__item {
      width: 116px;
      text-align: center;
      line-height: unset;
      font-size: 16px;
      font-weight: 500;
      color: #59595a;
      padding: 0;
      &.is-active {
        color: #0f80dc;
      }
    }
    .el-tabs__active-bar {
      background-color: #108aee;
    }
    .el-tabs__nav-wrap::after {
      background: rgba(26, 36, 56, 0.13);
      height: 1px;
    }
    .base {
      width: 100%;
      .card {
        padding: 56px 24px 62px;
        box-sizing: border-box;
        width: 286px;
        height: 333px;
        background: #f1f2f6;
        .item {
          height: 86px;
          cursor: pointer;
          .title {
            font-size: 20px;
            color: #393c40;
            line-height: 20px;
            font-weight: 500;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              transition: 0.3s;
              width: 0;
              // width: 18px;
              height: 2px;
              background: #108aee;
              top: 26px;
              left: 0;
            }
          }
          &:hover {
            .title {
              color: #108aee;
              &::after {
                width: 18px;
              }
            }
          }
          .sub_tit {
            font-size: 14px;
            color: #797c80;
            line-height: 22px;
            font-weight: 400;
            height: 44px;
          }
        }
        &.card_img {
          background-size: 286px 333px;
        }
      }
    }
  }
}
</style>

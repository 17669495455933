<template>
  <div class="home">
    <div class="banner">
      <el-carousel :interval="4000">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <div
            class="banner_img"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundColor: item.color,
            }"
          >
            <div class="banner-text">
              <div class="banner_title">园区工地全要素展示地图</div>
              <div class="banner-sub">
                汇集并直观展示企业各类型要素，并支持点选、搜索、标绘、事务处理等操作，实现一张地图可视化管理
              </div>
              <el-button class="btn">查看详情</el-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bg flex flex-col justify-between">
      <!-- 技术底座 -->
      <technical-base></technical-base>
      <!-- 应用 -->
      <app-view></app-view>
    </div>
    <!-- 生态市场 -->
    <ecology-market></ecology-market>
    <!-- 解决方案 -->
    <solution></solution>
    <!-- 服务保障 -->
    <service-assure></service-assure>
    <!-- 企业 -->
    <enterprise></enterprise>
    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import TechnicalBase from "./TechnicalBase";
import AppView from "./AppView";
import EcologyMarket from "./EcologyMarket";
import Solution from "./Solution";
import ServiceAssure from "./ServiceAssure";
import Enterprise from "./Enterprise";
import ContactUs from "./ContactUs";

export default {
  name: "home",
  components: {
    TechnicalBase,
    AppView,
    EcologyMarket,
    Solution,
    ServiceAssure,
    Enterprise,
    ContactUs,
  },
  data() {
    return {
      bannerList: [
        { img: require("@/assets/images/h_banner.png"), color: "#020709" },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.home {
  margin-top: 76px;
  &/deep/.banner {
    .el-carousel__item,
    .el-carousel,
    .el-carousel__container {
      width: 100%;
      height: 522px;
    }
    .banner_img {
      width: 100%;
      height: 100%;
      background-size: 1516px 522px;
      background-repeat: no-repeat;
      background-position: top;
      overflow: hidden;
      // padding-left: 168px;
      box-sizing: border-box;
      .banner-text {
        width: 1180px;
        margin: 0 auto;
      }
      .banner_title {
        font-size: 52px;
        font-weight: 400;
        color: #ffffff;
        line-height: 52px;
        margin-top: 113px;
      }
      .banner-sub {
        width: 454px;
        font-size: 16px;
        font-weight: 400;
        color: #b6bfd3;
        line-height: 30px;
        margin-top: 39px;
      }
      .btn {
        width: 188px;
        height: 61px;
        background: #0f80dc !important;
        padding: 0 !important;
        color: #fff !important;
        margin-top: 44px;
        border-color: transparent !important;
        border-radius: 1px;
        font-size: 19px;
      }
    }
    .el-carousel__indicators {
      display: none;
    }
    .el-carousel__arrow {
      background: transparent;
      font-size: 34px;
    }
  }
  .bg {
    height: 1391px;
    background-image: linear-gradient(180deg, #f1f2f6 0%, #fdfdff 100%);
    box-sizing: border-box;
    padding-bottom: 60px;
  }
}
</style>

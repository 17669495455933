<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        功能强大的技术底座
        <span>多年技术沉淀, 融合多项ICT技术能力, 支持业务快速灵活定制</span>
      </div>
      <div class="cards flex flex-wrap justify-between">
        <div class="card" v-for="item in list">
          <div
            class="title"
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
          >
            {{ item.name }}
          </div>
          <div class="base flex flex-col justify-between">
            <span class="ellipsis-2">{{ item.content }}</span>
            <div class="btns flex justify-between">
              <el-button v-for="it in item.btns">{{ it.name }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="more flex justify-center">更多></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "GIS服务",
          content:
            "提供强大的空间数据处理和图形表达能力，支撑地理空间分析、定位、搜索、空间模拟、空间决策等功能",
          img: require("@/assets/images/ban2.png"),
          btns: [
            { type: "1", name: "实时音视频" },
            { type: "1", name: "空间计算" },
            { type: "1", name: "地址算法" },
          ],
        },
        {
          name: "物联网",
          content:
            "提供物联网相关设备管理、连接通信、数据处理等方面能力，实现“云网边端安全”的组件化产品组合",
          img: require("@/assets/images/ban3.png"),
          btns: [
            { type: "1", name: "融合通信" },
            { type: "1", name: "设备定位" },
            { type: "1", name: "设备联动" },
          ],
        },
        {
          name: "低代码",
          content:
            "提供易于理解的可视化功能设计工具，结合少量代码快速开发应用程序，赋能业务快速创新",
          img: require("@/assets/images/ban6.png"),
          btns: [
            { type: "1", name: "流程引擎" },
            { type: "1", name: "规则引擎" },
            { type: "1", name: "表单引擎" },
          ],
        },
        {
          name: "连接拓展",
          content:
            "提供API接口、数据、服务、设备等内容连接和治理能力，实现系统安全稳定的拓展强化",
          img: require("@/assets/images/ban5.png"),
          btns: [
            { type: "1", name: "AI增强" },
            { type: "1", name: "生产数据" },
            { type: "1", name: "资产设备" },
          ],
        },
        {
          name: "支撑体系",
          content:
            "提供场景数字化建设所需的支撑功能，建立标准通用的组织接口、权限、地址等功能",
          img: require("@/assets/images/ban1.png"),
          btns: [
            { type: "1", name: "组织结构" },
            { type: "1", name: "权限体系" },
            { type: "1", name: "标准地址" },
          ],
        },
        {
          name: "基础服务",
          content:
            "提供融合、智能、安全、开放的数字化能力，提升研发效能和数字技术管理能力",
          img: require("@/assets/images/ban4.png"),
          btns: [
            { type: "1", name: "全局搜索" },
            { type: "1", name: "API网关" },
            { type: "1", name: "日志服务" },
          ],
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang='less' scoped>
.main {
  padding-top: 60px;
  box-sizing: border-box;
}
.index_tit {
  color: #000;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  height: 74px;
  span {
    font-size: 16px;
    color: #797c80;
    line-height: 16px;
    font-weight: 400;
  }
}
.more {
  font-size: 16px;
  color: #0f80dc;
  line-height: 16px;
  font-weight: 400;
  margin-top: 34px;
  cursor: pointer;
}
.cards {
  margin-top: 30px;

  &::after {
    content: "";
    display: block;
    width: 384px;
  }
  .card {
    width: 384px;
    height: 246px;
    background: #fff;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    top: 0;
    &:hover {
      top: -4px;
      transition: 0.3s;
      box-shadow: 0px 5px 24px 0px rgba(13, 21, 41, 0.07);
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 109px;
      background-size: cover;
      font-size: 20px;
      color: #ffffff;
      line-height: 20px;
      font-weight: 500;
      padding: 15px 20px;
      box-sizing: border-box;
    }
    .base {
      padding: 12px 20px 24px;
      box-sizing: border-box;
      height: 137px;
      span {
        font-size: 14px;
        color: #797c80;
        line-height: 22px;
        font-weight: 400;
      }
      .btns {
        &/deep/.el-button {
          cursor: initial !important;
          width: 108px;
          height: 44px;
          background: #f1f2f6;
          border-color: transparent;
          padding: 0 !important;
          font-size: 16px;
          color: #797c80;
          text-align: center;
          line-height: 16px;
          font-weight: 400;
          border-radius: 1px;
          &:hover {
            background: #ffffff !important;
            border: 1px solid #0f80dc !important;
            color: #0f80dc !important;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        用心服务, 提供稳定的技术保障
      </div>
      <div class="base flex justify-between">
        <div class="item flex justify-between" v-for="item in list">
          <i class="iconfont" :class="item.icon"></i>
          <div class="desc">
            <div class="title">{{ item.name }}</div>
            <div class="text">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "专属产品经理",
          content: "由专属产品经理打造适合业务发展的建设方案",
          icon: "iconrenyuanguanlibeifen",
        },
        {
          name: "研发技术支撑",
          content: "专属研发群，对接技术实现过程中发现的问题",
          icon: "iconyanfajishu",
        },
        {
          name: "售后运维",
          content: "为上线项目提供系统运维服务，保障业务开展",
          icon: "icona-renyuanguanlibeifen3",
        },
        {
          name: "开发文档",
          content: "提供全面的产品文档、新手教程及工具",
          icon: "icona-renyuanguanlibeifen4",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang='less' scoped>
.main {
  box-sizing: border-box;
  padding-top: 60px;
  height: 314px;
  background-image: url("~@/assets/images/ServiceAssure.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 1516px 314px;
  background-color: #0f80dc;
}
.index_tit {
  color: #fff;
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
}
.base {
  margin-top: 48px;
  color: #fff;
  .item {
    width: 274px;
    height: 88px;
    .iconfont {
      font-size: 40px;
      &.iconrenyuanguanlibeifen,
      &.icona-renyuanguanlibeifen3 {
        font-size: 37px;
      }
      &.icona-renyuanguanlibeifen4 {
        font-size: 34px;
      }
    }
    .desc {
      width: 230px;
      .title {
        font-size: 20px;
        color: #ffffff;
        line-height: 20px;
        font-weight: 500;
      }
      .text {
        font-size: 16px;
        color: #ebedf1;
        line-height: 28px;
        font-weight: 400;
        margin-top: 12px;
      }
    }
  }
}
</style>

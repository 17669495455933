import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    homeBanner:false,
    titleCompleted:"举报投诉",
    dialogVisible:true
  },
  mutations: {
    
  },
  actions: {

  },
  modules: {}
});

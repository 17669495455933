<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        面向场景的全栈解决方案
        <span>源于实践，面向业务场景，提供端到端软硬件融合的全栈解决方案</span>
      </div>
      <div class="base flex justify-between">
        <div class="item" v-for="item in list">
          <img :src="item.img" alt="" />
          <div class="subtit ellipsis">{{ item.subtitle }}</div>
          <div class="title ellipsis">{{ item.name }}</div>
          <div class="desc ellipsis-3">{{ item.content }}</div>
          <div class="btn flex justify-between align-center">
            探索 <img src="@/assets/images/arrow.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "智慧工地",
          subtitle: "WISDOM SITE",
          content:
            "通过多屏幕互动、多人协同等方式，实现人机料环多系统资源的高效调度，全面提升工地施工和管理效能",
          img: require("@/assets/images/plan1.jpeg"),
        },
        {
          name: "智慧园区",
          subtitle: "WISDOM PARK",
          content:
            "实现园区安防、物业、日常管理的可视化、智慧化统筹运营，让园区管理更安全、更高效、更节能",
          img: require("@/assets/images/plan2.jpeg"),
        },
        {
          name: "智慧电商",
          subtitle: "SMART E-COMMERCE",
          content:
            "运用数据可视化、物联网等技术，实现意向客户分布、消费热区分析、物流可视化管理等功能",
          img: require("@/assets/images/plan3.jpeg"),
        },
        {
          name: "智慧教育",
          subtitle: "WISDOM EDUCATION",
          content:
            "运用实时音视频、低代码等技术，实现远程教学、在线测评、智能排课等功能",
          img: require("@/assets/images/plan4.jpeg"),
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang='less' scoped>
.main {
  box-sizing: border-box;
  padding-top: 60px;
  height: 633px;
  background-image: url("~@/assets/images/tiankong.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 1516px 633px;
  background-color: #fefefe;
}
.index_tit {
  color: #000;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  height: 74px;
  span {
    font-size: 16px;
    color: #797c80;
    line-height: 16px;
    font-weight: 400;
  }
}

.base {
  margin-top: 60px;
  .item {
    width: 286px;
    height: 359px;
    box-shadow: 0px 3px 17px 0px rgba(42, 63, 109, 0.06);
    background: #ffffff;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    &:hover {
      box-shadow: 0px 5px 24px 0px rgba(13, 21, 41, 0.11);
      .btn {
        width: 70px;
      }
    }
    & > img {
      width: 286px;
      height: 146px;
      object-fit: cover;
    }
    & > div {
      margin: 0 24px;
    }
    .subtit {
      font-family: AlibabaPuHuiTi-Regular;
      font-size: 12px;
      color: #0f80dc;
      line-height: 12px;
      font-weight: 400;
      margin-top: 18px;
    }
    .title {
      font-size: 30px;
      color: #1a1a1a;
      line-height: 30px;
      font-weight: 400;
      margin-top: 8px;
    }
    .desc {
      font-size: 14px;
      color: #797c80;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: 400;
      margin-top: 12px;
    }
    .btn {
      position: absolute;
      bottom: 36px;
      width: 60px;
      font-size: 16px;
      color: #0f80dc;
      line-height: 16px;
      font-weight: 400;
      margin-top: 12px;
      transition: 0.3s;
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

/* Router route */

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/contactus",//方案咨询
    name: "contactus",
    component: () => import("@/components/common/contactus")
  },
 {
    path: "/case",//合作案例
    name: "CooperationCase",
    component: () => import("@/views/cooperationCase/CooperationCase")
  },
  {
    path: "/case/detail",//合作案例详情
    name: "CooperationCaseDetail",
    component: () => import("@/views/cooperationCase/CooperationCaseDetail")
  },
  // 开发能力
  {
    path: "/tech",//开发能力
    name: "tech",
    component: () => import("@/views/tech/tech")
  },
  //服务与支持
  {
    path: "/service/doc",//帮助文档  helpdocument
    name: "HelpDocument",
    component: () => import("@/views/servicesAndSupport/helpDocument/HelpDocument"),
    children:[
      {
        path: "detail",// 产品--- ios工程配置常见问题
        name: "Ios",
        component: () => import("@/views/servicesAndSupport/helpDocument/product/Ios")
      },
      {
        path: '',
        redirect: '/service/doc/detail'
      }
    ]
  },
  // {
  //   path: "/service/helpdocument/:id",//产品--- ios工程配置常见问题
  //   name: "Ios",
  //   component: () => import("@/views/servicesAndSupport/helpDocument/product/Ios")
  // },

  {
    path: "/service/tool",//工具导航  toolnavigation
    name: "ToolNavigation",
    component: () => import("@/views/servicesAndSupport/toolNavigation/ToolNavigation")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;

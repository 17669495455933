<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        专业丰富的产业生态市场
        <span>精选优质商品，强化平台物联网能力，实现物理世界的实时感知</span>
      </div>
      <div class="base flex justify-between">
        <div class="item" v-for="item in list">
          <img :src="item.icon" alt="" />
          <div class="title">{{ item.name }}</div>
          <div class="label" v-for="it in item.list">
            {{ it.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "DTU/网关",
          icon: require("@/assets/images/icon_dtu.png"),
          list: [
            { name: "以太网网关" },
            { name: "4G数据采集终端" },
            { name: "边缘计算网关" },
          ],
        },
        {
          name: "定位设备",
          icon: require("@/assets/images/icon_dingweishebei.png"),
          list: [
            { name: "北斗定位" },
            { name: "GPS定位" },
            { name: "UWB高精度定位" },
          ],
        },
        {
          name: "智能传感",
          icon: require("@/assets/images/icon_zhinengchuangan.png"),
          list: [
            { name: "LoRaWAN门磁" },
            { name: "智慧井盖传感器" },
            { name: "LoRaWAN控烟检测" },
          ],
        },
        {
          name: "摄像头",
          icon: require("@/assets/images/icon_shexiangtou.png"),
          list: [
            { name: "工地摄像头" },
            { name: "园区摄像头" },
            { name: "城市治理摄像头" },
          ],
        },
        {
          name: "考勤办公",
          icon: require("@/assets/images/icon_kaoqinbangong.png"),
          list: [
            { name: "智能门禁" },
            { name: "智能测温" },
            { name: "会议智慧屏" },
          ],
        },
        {
          name: "热门硬件",
          icon: require("@/assets/images/icon_yingjian.png"),
          list: [
            { name: "车辆识别一体机" },
            { name: "工业网关" },
            { name: "LTE CAT.1模组" },
          ],
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang='less' scoped>
.main {
  box-sizing: border-box;
  padding-top: 60px;
  height: 583px;
  background-image: linear-gradient(180deg, #f1f2f6 0%, #fdfdff 100%);
}
.index_tit {
  color: #000;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  height: 74px;
  span {
    font-size: 16px;
    color: #797c80;
    line-height: 16px;
    font-weight: 400;
  }
}
.base {
  margin-top: 60px;
  .item {
    width: 186px;
    height: 295px;
    background: #ffffff;
    box-shadow: 0px 3px 17px 0px rgba(42, 63, 109, 0.06);
    padding: 24px 20px 40px;
    box-sizing: border-box;
    transition: 0.3s;
    img {
      width: 54px;
      height: 54px;
    }
    .title {
      margin-top: 26px;
      margin-bottom: 43px;
      font-size: 20px;
      color: #393c40;
      line-height: 20px;
      font-weight: 500;
    }
    .label {
      font-size: 16px;
      color: #797c80;
      line-height: 16px;
      font-weight: 400;
      margin-top: 20px;
      cursor: pointer;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        transition: 0.3s;
        width: 0;
        // width: 18px;
        height: 2px;
        background: #108aee;
        top: 22px;
        left: 0;
      }
      &:hover {
        color: #108aee;
        &::after {
          width: 18px;
        }
      }
    }
    &:hover {
      box-shadow: 0px 5px 24px 0px rgba(13, 21, 41, 0.11);
    }
  }
}
</style>

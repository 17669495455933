<template>
  <div class="footer">
    <div class="type-area">
      <div class="footer-title">挖掘时空数据价值，赋能业务转型升级</div>
      <div class="footer-main">
        <div class="footer-item">
          <div class="item-title">热门产品</div>
          <div><a href="#">人员定位地图</a></div>
          <div><a href="#">音视频通话</a></div>
          <div><a href="#">视频监控地图</a></div>
          <div><a href="#">固定资产地图</a></div>
          <div><a href="#">客户地图</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">解决方案</div>
          <div><a href="#">智慧园区</a></div>
          <div><a href="#">智慧治理</a></div>
          <div><a href="#">智慧工地</a></div>
          <div><a href="#">人员安防</a></div>
          <div><a href="#">应急调度</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">资源下载</div>
          <div><a href="#">开发文档</a></div>
          <div><a href="#">SDK下载</a></div>
          <div><a href="#">教学视频</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">服务与支持</div>
          <div><a href="#">在线演示</a></div>
          <div><a href="#">公司公告</a></div>
          <div><a href="#">合作咨询</a></div>
          <div><a href="#">联系我们</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">联系我们</div>
          <div><a href="#">咨询电话：13980789940</a></div>
          <div><a href="#">技术支持：18583368218</a></div>
          <div><a href="#">公司地址: 成都市高新区菁蓉汇6栋A座2楼</a></div>
          <div class="qrcodes">
            <img src="./images/footer/weixinma_1.png" alt="" />
            <img src="./images/footer/weixinma_2.png" alt="" />
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div>
          © 2016-2021 奇点闪耀版本所有
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >苏ICP备2024084784号</a
          >
        </div>
        <div><span>法律声明</span> | <span>隐私政策</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.footer {
  height: 602px;
  background: #0272c9;
  overflow: hidden;
  .type-area {
    width: 1180px;
    margin: 0 auto;
    .footer-title {
      margin-top: 63px;
      font-size: 42px;
      line-height: 58px;
      color: #fff;
      position: relative;
      &::after {
        content: "“";
        position: absolute;
        font-size: 72px;
        left: -30px;
      }
    }
    .footer-main {
      display: flex;
      justify-content: space-between;
      div {
        font-size: 14px;
        line-height: 20px;
        margin-top: 17px;
        a {
          color: rgba(255, 255, 255, 0.58);
          &:hover {
            color: #fff;
          }
        }
        &:nth-child(2) {
          margin-top: 24px;
        }
        &.qrcodes {
          margin-top: 32px;
          width: 195px;
          display: flex;
          justify-content: space-between;
          img {
            width: 92px;
            height: 92px;
          }
        }
      }
      .item-title {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        margin-top: 68px;
      }
      .footer-item {
        margin-top: 0 !important;
      }
    }
    .footer-bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      margin-top: 36px;
      padding-top: 35px;
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.58);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      & > div:last-child {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.58);
        span {
          &:hover {
            color: #ebedf1;
          }
        }
      }
      div a {
        color: rgba(255, 255, 255, 0.58);
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
